
import React, {Component} from 'react';
import '../css/BotaoMenu.css';
import '../css/Menu.css';

export default class BotaoMenu extends Component {

    // if(props.onClick){
    //     console.log('ggg')
    //     console.log(props.teste)
    // }
    // constructor(props){
    //     super(props)
    //     this.state = {
    //         estendido: false,
    //         imagem: menu
    //     }
    // }

    // click() {
    //     console.log(this.state.estendido);
    //     if(this.state.estendido){
    //         console.log('esconder');
    //         this.encolherMenu()

    //         this.setState({estendido: false, imagem: menu})
    //     } else {
    //         console.log('estender')
    //         this.estenderMenu()
    
    //         this.setState({estendido: true, imagem: fechar})
    //     }
    // }

    // encolherMenu(){
    //     let nav = document.querySelector('nav')
    //     nav.style.height = '100%'
    //     nav.style.position = 'static'
    //     nav.style.alignItems = 'center'

    //     let ul = document.querySelector('ul')
    //     ul.style.display = 'block'
        
    //     let botao = document.querySelector('.botaomenu')
    //     botao.style.position = 'none'
    //     botao.style.marginTop = '0%'
    //     botao.style.marginRight = '5%'
    // }

    // estenderMenu(){
    //     let nav = document.querySelector('nav')
    //     nav.style.height = '100vh'
    //     nav.style.position = 'fixed'
    //     nav.style.alignItems = 'flex-start'

    //     let ul = document.querySelector('ul')
    //     ul.style.display = 'flex'
        
    //     let botao = document.querySelector('.botaomenu')
    //     botao.style.marginTop = '5%'
    //     botao.style.marginRight = '5%'
    // }

    render(){
        //let {imagem} = this.state

        return (
            // <button onClick={() => this.click()} className='botaomenu'>
            <button onClick={this.props.onClick} className='botaomenu'>
                <img id='botaomenu-imagem' src={this.props.icone} alt='botao menu'/>
            </button>
        );
    }
}
