import '../css/Home.css';
import Apresentacao from './Apresentacao';
import Interesses from './Interesses';
import Menu from './Menu';
import Projetos from './Projetos';
import Rodape from './Rodape';
import Saudacao from './Saudacao';
import Sobre from './Sobre';

function Home(){
    return (
        <div>
            <div className='home'>
                <div className='superior'>
                    <Menu/>
                </div>
                <main className='inferior'>
                    <div className='esquerdo'>
                        <Saudacao/>
                    </div>
                    <div className='direito'>
                        <Apresentacao/>
                    </div>
                    <Sobre/>
                    <Interesses/>
                    <Projetos/>
                </main>
                <Rodape/>
            </div>
        </div>
    );
}

export default Home;