import '../css/Saudacao.css';

function Saudacao() {
    return (
        <header className='saudacao'>
            <div className='saudacao-corpo'>
                <p id='ola'>Olá! eu sou</p>
                <h2>Gleison A. Araujo</h2>
                <p>Licenciado em Matemática e estudante de Ciência da Computação</p>
            </div>
        </header>
    );
}

export default Saudacao;