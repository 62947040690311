import '../css/Rodape.css';

function Rodape() {
    return (
        <footer>
            <p>© 2022 Desenvolvido por Gleison A. Araujo</p>
        </footer>
    );
}

export default Rodape;