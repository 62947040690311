import foto from '../assets/gleison.jpg';
import '../css/Apresentacao.css'

function Apresentacao() {
    return (
        <div className='apresentacao'>
            <div className='apresentacao-corpo'>
                <img src={foto} alt='Gleison'/>
                <div className='apresentacao-texto'>
                    <h1>Gleison Alves de Araujo</h1>
                    <span>Licenciado em Mátemática e Estudante de Ciência da Computação.</span>
                    <p>Pernambuco</p>
                    <p id='apresentacao-contato'>contato@gleison.dev.br</p>
                </div>
            </div>
        </div>
    );
}

export default Apresentacao;