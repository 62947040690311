import React, {Component} from 'react';
import '../css/Menu.css';
import BotaoMenu from './BotaoMenu';
import menu from '../assets/menu.png';
import fechar from '../assets/fechar.png';

// function clickMenu(){
//     return (console.log('oi2'))
// }

export default class Menu extends Component {

    constructor(props){
        super(props)
        this.state = {
            estendido: false,
            imagem: menu
        }
    }

    click() {
        const wLargura = window.innerWidth;
        if(wLargura < 730){
            // console.log(this.state.estendido);
            if(this.state.estendido){
                // console.log('esconder');
                this.encolherMenu()

                this.setState({estendido: false, imagem: menu})
            } else {
                // console.log('estender')
                this.estenderMenu()
        
                this.setState({estendido: true, imagem: fechar})
            }
        }
    }

    encolherMenu(){
        let nav = document.querySelector('nav')
        nav.style.height = '100%'
        nav.style.position = 'static'
        nav.style.alignItems = 'center'

        let ul = document.querySelector('ul')
        ul.style.display = 'block'
        
        let botao = document.querySelector('.botaomenu')
        botao.style.position = 'none'
        botao.style.marginTop = '0%'
        botao.style.marginRight = '5%'
    }

    estenderMenu(){
        let nav = document.querySelector('nav')
        nav.style.height = '100vh'
        nav.style.position = 'fixed'
        nav.style.alignItems = 'flex-start'

        let ul = document.querySelector('ul')
        ul.style.display = 'flex'
        
        let botao = document.querySelector('.botaomenu')
        botao.style.marginTop = '5%'
        botao.style.marginRight = '5%'
    }
    render(){
        return (
            <nav>
                <ul>
                    <li><a href='#sobre' onClick={() => this.click()}>Sobre</a></li>
                    <li><a href='#interesses' onClick={() => this.click()}>Interesses</a></li>
                    <li><a href='#projetos' onClick={() => this.click()}>Projetos</a></li>
                </ul>
                <BotaoMenu onClick={() => this.click()} icone={this.state.imagem}/>
            </nav>
        );
    }
}
// this.state = {
//     estendido: false,
//     imagem: menu

// }




