import '../css/Interesses.css';
import java from '../assets/interesses/java.png';
import python from '../assets/interesses/python.png';
import js from '../assets/interesses/js.png';
import php from '../assets/interesses/php.png';
import laravel from '../assets/interesses/laravel.png';
import react from '../assets/interesses/react.png';
import android from '../assets/interesses/android.png';
import ia from '../assets/interesses/ia.png';
import linux from '../assets/interesses/linux.png';
import rede from '../assets/interesses/rede.png';

function Interesses(){
    return (
        <div className="interesses" id='interesses'>
            <h3>Interesses</h3>
            <div>
                <p>Linguagens de Programação</p>
                <img src={java} alt='java'/>
                <img src={python} alt='python'/>
                <img src={js} alt='javascript'/>
                <img src={php} alt='php'/>
            </div>
            <div>
                <p>Framework/Biblioteca</p>
                <img src={android} alt='android'/>
                <img src={laravel} alt='laravel'/>
                <img src={react} alt='react'/>
            </div>
            <div>
                <p>Inteligência Artificial/Apredizagem de Máquina/Reconhecimento de Padrões</p>
                <img src={ia} alt='ia'/>
            </div>
            <div>
                <p>Linux</p>
                <img src={linux} alt='linux'/>
            </div>
            <div>
                <p>Redes e Segurança de Computadores</p>
                <img src={rede} alt='rede'/>
            </div>
        </div>

    );
}

export default Interesses;