import '../css/Sobre.css';

function Sobre(){
    return (
        <div className="sobre" id='sobre'>
            <h3>Sobre</h3>
            <p>Trabalha no serviço público desde 2006, licenciado em Matemática pela UPE - Campos Garanhuns, estudou Ciência da Computação pela UFAPE - UFRPE.</p>
            <p>Atraído por ciências exatas, ver na matemática uma forma de entender um pouco da infinidade de possibilidades de avanços tecnológicos e humanos. De forma complementar acredita que a Ciência da Computação através dos sistemas computacionais presente no contidiano das pessoas, em forma de serviços e produtos, trazem diversos benefícios e bem-estar para a vida como: comodidade, entretenenimento, comunicação etc... Proporcionando melhor qualidade de vida se bem utilizada.</p>
            <p>Busca incessantemente aprender novas tecnológias e praticá-las, pretedendo contrubuir no avanço social e humano através do desenvolvimento de softwares para web e mobile. </p>
        </div>
    );
}

export default Sobre;